import React from 'react';

const EventCount = () => {
    return (
        <section className="event_counter_area" id='about-solution'>
            <div className="container">
                <div className="row align-items-center justify-content-center ">
                    <div className="col-lg-6">
                        <div className="event_text text-white wow fadeInLeft">
                            <h3 className='title-about-mobile'>Giới thiệu về giải pháp</h3>
                            <p className='mt-5 text-des-about text-white'>Là hệ thống duy nhất hiện nay mang đến khả năng xác thực nguồn gốc và chất lượng sản phẩm với độ chính xác cực kì cao. <br /> Hệ thống Blockchain được xây dựng bởi Công ty CP Vietnam Blockchain (VBC) - đối tác chiến lược và là một trong những đơn vị uy tín thực hiện giải pháp phần mềm Truy xuất nguồn gốc đầu tiên ở VN.</p>
                            <button className='mt-3' type='link' href='#contact-section'><a href='#contact-section'>Đăng ký sử dụng</a></button>
                        </div>
                    </div>
                    <div className="col-lg-6 position-relative h-100 highlight-col">
                        <img src='https://res.cloudinary.com/agridential/image/upload/v1620283634/images/Group_250_1_lqbicb.png' alt='ipad_img' className='img_ipad_section' />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default EventCount;