import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';

class FooterErp extends Component {
    render() {
        let FooterData = this.props.FooterData;
        let { fClass } = this.props;
        return (
            <footer className={`footer_area h_footer_dark ${fClass}`}>
                <div className="container">
                    <div className="d-flex text-center w-100 flex-column">
                        <h3 className='text-white font-weight-bold'>Thông tin liên hệ</h3>
                        <span className='text-white'>Đơn vị chủ trì đề tài xác thực và truy xuất nguồn gốc trên tiêu Việt Nam</span>
                        <span className='text-white mt-3'>
                            Địa chỉ: Phòng 202-C4, Trường Đại học Bách Khoa Hà Nội - Số 1, Đường Đại Cồ Việt, Hà Nội <br />
Người đại diện: Mr. Hoàng Quốc Tuấn<br />
Số điện thoại:(+84) 24 3868 2470<br />
Di động: 0972943999/ 0929071579<br />
Email: NasusBplus@gmail.com<br />
                        </span>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterErp;