import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sticky from 'react-stickynode';

const CustomNavbar = (props) => {
    const { mClass, nClass, cClass, slogo, hbtnClass } = props;

    return (
        <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
            <header className="header_area">
                <nav className={`navbar  menu_one py-3 ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                        </Link>
                        <div className='position-relative'>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="menu_toggle">
                                    <span className="hamburger">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <span className="hamburger-cross">
                                        <span></span>
                                        <span></span>
                                    </span>
                                </span>
                            </button>
                            <div className='dropdown-menu' role="menu" id="navbarSupportedContent">
                                <li className="nav-item nav-item-top-none"><a exact title="Service" className="nav-link" href='#home-section'>Trang chủ</a></li>
                                <li className="nav-item"><a exact title="Service Details" className="nav-link" href='#about-solution'>Giới thiệu về giải pháp</a></li>
                                <li className="nav-item"><a exact title="Service" className="nav-link" href='#partner-section'>Đối tác của chúng tôi</a></li>
                                <li className="nav-item"><a exact title="Service Details" className="nav-link" href='#feature-section'>Tính năng nổi bật</a></li>
                                <li className="nav-item"><a exact title="Service Details" className="nav-link" href='#benefit-section'>Lợi ích cho các bên</a></li>
                                <li className="nav-item"><a exact title="Service Details" className="nav-link" href='#process-section'>Quy trình nghiệp vụ/ <br />cách thức hoạt động</a></li>
                                <li className="nav-item"><a exact title="Service Details" className="nav-link" href='#register-section'>Hướng dẫn đăng ký</a></li>
                                <li className="nav-item"><a exact title="Service Details" className="nav-link" href='#faq-section'>Câu hỏi thường gặp</a></li>
                                <li className="nav-item"><a exact title="Service Details" className="nav-link" href='#contact-section'>Liên hệ</a></li>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </Sticky>
    );
}

export default CustomNavbar;