import React from 'react';

const EventDetails = () => {
    return (
        <section className="event_promotion_area" id='benefit-section'>
            <div className='container' >
                <div className="event_promotion_inner">
                    <div className="event_img">
                        <img src={require('../../img/home-event/promo.jpg')} alt="" />
                    </div>
                    <div className="row event_promotion_info align-items-center">
                        <div className="col-md-3 col-12">
                            <div className="hosting_title security_title text-center">
                                <h2 className="wow fadeInUp" data-wow-delay="0.2s">Lợi ích cho <br />các bên</h2>
                            </div>
                        </div>
                        <div className="col-md-9 col-12">
                            <div className='bg-gradient-yellow border-benefit py-4 row position-relative'>
                                <div>
                                    <div className='d-flex flex-row  align-items-center py-2'>
                                        <div>
                                            <div className='bullet-benefit'>
                                                1
                                            </div>
                                        </div>
                                        <div className=' pl-2 block-benefit'>
                                            <p className='mb-0'>Xác thực và truy xuất nguồn gốc xuất xứ, chất lượng sản phẩm nhanh chóng, độ tin cậy cao.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-row align-items-center py-2'>
                                        <div>
                                            <div className='bullet-benefit'>
                                                2
                                            </div>
                                        </div>
                                        <div className=' pl-2 block-benefit'>
                                            <p className='mb-0'>Hỗ trợ quản lý chuỗi sản xuất, chế biến và thương mại hóa sản phẩm.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-row align-items-center py-2'>
                                        <div>
                                            <div className='bullet-benefit'>
                                                3
                                            </div>
                                        </div>
                                        <div className=' pl-2 block-benefit'>
                                            <p className='mb-0'>Quảng bá và bảo vệ thương hiệu cho sản phẩm.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-row  align-items-center py-2'>
                                        <div>
                                            <div className='bullet-benefit'>
                                                4
                                            </div>
                                        </div>
                                        <div className=' pl-2 block-benefit'>
                                            <p className='mb-0'>Thực hiện hoạt động quản lý nhà nước về truy xuất nguồn gốc xuất xứ, chất lượng.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-row align-items-center py-2'>
                                        <div>
                                            <div className='bullet-benefit'>
                                                5
                                            </div>
                                        </div>
                                        <div className='pl-2 block-benefit'>
                                            <p className='mb-0'>Liên kết các đối tác dịch vụ liên quan trên hệ thống.</p>
                                        </div>
                                    </div>
                                </div>
                                <img src='https://res.cloudinary.com/agridential/image/upload/v1620373418/images/Mask_Group_2x_rgmrmn.png' className='img-benefit' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default EventDetails;