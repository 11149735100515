import React from 'react';

const FaqSection = () => {
    return (
        <section className="faq_area bg_faq sec_pad position-relative" id='faq-section'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tab-content faq_content" id="myTabContent">
                            <div className="tab-pane fade show active" id="purchas" role="tabpanel" aria-labelledby="purchas-tab">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Câu hỏi thường gặp</h3>
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Truy xuất nguồn gốc là gì?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body">
                                                Truy xuất nguồn gốc là khả năng theo dõi, nhận diện được một đơn vị sản phẩm thông qua tất cả các giai đoạn từ tìm nguồn nguyên liệu, sản xuất, chế biến, vận chuyển và phân phối ra thị trường.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Đặc điểm của xác thực nguồn gốc?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div className="card-body">
                                                Xác thực là hành động nhằm chứng thực/công nhận nguồn gốc của sản phẩm thông qua dữ liệu đặc trưng về hóa lý sinh của sản phẩm. Dữ liệu đặc trưng về hóa lý sinh của sản phẩm là dữ liệu nội tại của sản phẩm và không thể làm giả.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Block-chain là gì?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                            <div className="card-body">
                                                Blockchain là một cơ sở dữ liệu phân cấp lưu trữ thông tin trong các khối thông tin được liên kết với nhau bằng mã hóa và mở rộng theo thời gian. Blockchain được thiết kế để chống lại việc thay đổi của dữ liệu.
                                                </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingfour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                                    Tại sao lại là Blockchain-plus?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                                            <div className="card-body">
                                                Blockchain Plus (Bplus) là hệ thống được xây dựng trên nền tảng công nghệ Blockchain kết hợp với kỹ thuật phân tích để thu nhận dữ liệu đặc thù của đối tượng nhằm phục vụ xác thực và truy xuất chính xác nguồn gốc sản phẩm. Nhiệm vụ của hệ thống Bplus: 1- Minh bạch hóa thông tin; 2- Xác thực và Truy xuất nguồn gốc; 3- Quản lý chuỗi cung ứng; 4- Hỗ trợ các dịch vụ phụ trợ trong chuỗi cung ứng.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingfive">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                                    Tham gia cùng chúng tôi?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                                            <div className="card-body">
                                                Các đơn vị sản xuất, chế biến và thương mại sản phẩm nông sản. Đơn vị quản lý nhà nước. Đơn vị cung cấp dịch vụ phụ trợ cho chuỗi cung ứng nông sản.
                                               </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img className='img-faq-one' src='https://res.cloudinary.com/agridential/image/upload/v1620378212/images/Mask_Group_epgguv.svg' alt=''></img>
            <img className='img-faq-two' src='https://res.cloudinary.com/agridential/image/upload/v1620378396/images/Group_221_ci3ebb.svg' alt=''></img>

        </section>
    )
}
export default FaqSection;