import React, { Component } from "react";
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import HomeEvent from "./Pages/Home-event";
import ScrollToTopRoute from "./ScrollToTopRoute";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={HomeEvent} />
          <ScrollToTopRoute component={HomeEvent} /> 
        </Switch>
      </Router>
    );
  }
}

export default hot(module)(App);