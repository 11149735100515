import React, { Component } from 'react';
import Sectitle from './Sectitle';

class SupportIntegration extends Component {
    constructor() {
        super();
        this.state = {
            integrationItem: [
                {
                    "id": 100,
                    "iImage": "https://res.cloudinary.com/agridential/image/upload/v1620298024/images/Group_251_upambf.svg",
                    "text": "Đăng ký"
                },
                {
                    "id": 101,
                    "iImage": "https://res.cloudinary.com/agridential/image/upload/v1620298062/images/Group_252_vkt3dc.svg",
                    "text": "Tạo mới sổ nông vụ"
                },
                {
                    "id": 102,
                    "iImage": "https://res.cloudinary.com/agridential/image/upload/v1620298122/images/Group_253_snajzm.svg",
                    "text": "Cập nhật thông tin mùa vụ, sản phẩm"
                },
                {
                    "id": 103,
                    "iImage": "https://res.cloudinary.com/agridential/image/upload/v1620298120/images/Group_254_uqkxem.svg",
                    "text": "Quản lý, thanh tra"
                },
                {
                    "id": 104,
                    "iImage": "https://res.cloudinary.com/agridential/image/upload/v1620298117/images/Group_255_norde3.svg",
                    "text": "Thu hoạch, kích hoạt tem"
                },
            ]
        };
    }

    render() {
        return (
            <section className="support_integration_area bg-white" id='process-section'>
                <div className="container">
                    <Sectitle sClass="sec_title text-center mb_70 text-black" Title='Quy trình nghiệp vụ/ Cách thức hoạt động' TitleP='' />
                    <div className="w-100  text-center">
                        <div className="col-lg-12 col-md-10 col-sm-12">
                            <div className=" mb-5 overflow-scroll-mobile ">
                                {this.state.integrationItem.map(post => (
                                    <div className="col-lg-2 col-md-2 col-sm-2 px-2 card-mobile-step" key={post.id}>
                                        <a href="/#" className="s_integration_item">
                                            <img src={post.iImage} alt="" />
                                            <h5 className='text-black font-weight-bold'>{post.text}</h5>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button className='mt-3' type='link' href='#contact-section'><a href='#contact-section'>Bạn cần tư vấn?</a></button>
                    </div>
                </div>
            </section>
        )
    }
}
export default SupportIntegration;
