import React, { Component } from 'react';
import Sectitle from './Sectitle';

class SupportIntegration extends Component {
    constructor() {
        super();
        this.state = {
            integrationItem: [
                {
                    id: 0,
                    title: "Đăng ký",
                    des: "Nông hộ đăng kí với hợp tác xã tại địa phương để được cấp tài khoản"
                },
                {
                    id: 1,
                    title: "Đăng nhập",
                    des: "Chọn một trong các mục ở trên website để đăng nhập hoặc - Gõ cụm từ “ Agridential” tại CH Play hoặc App Store để cài đặt ứng dụng"
                },
                {
                    id: 2,
                    title: "Kích hoạt",
                    des: "Vui lòng hoàn thành biểu mẫu bên dưới để xem hướng dẫn chi tiết"
                }
            ]
        };
    }

    render() {
        return (
            <section className="bg_faq" id='contact-section'>
                <div className="container bg_contact py-5 contact-us">
                    <Sectitle sClass="sec_title text-center mb_70 text-black" Title='Liên hệ với chúng tôi' TitleP='' white={true} />
                    <div className="row flex-row-reverse">
                        <div className="col-lg-12 col-md-10 col-sm-12 ">
                            <div className="row justify-content-center mb-5 card-process-area">
                               <input placeholder='Công ty/HTX' className='w-100'/>
                               <input placeholder='Số điện thoại' className='w-100'/>
                               <input placeholder='Email' className='w-100'/>
                               <input placeholder='Nội dung' className='w-100'/>
                               <div>
                                   <button className='btn-send'>Tham gia cùng chúng tôi</button>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default SupportIntegration;
