import React, { Component } from 'react';
import Sectitle from './Sectitle';

class SupportIntegration extends Component {
    constructor() {
        super();
        this.state = {
            integrationItem: [
                {
                    id: 0,
                    title: "Đăng ký",
                    des: "Nông hộ đăng kí với hợp tác xã tại địa phương để được cấp tài khoản"
                },
                {
                    id: 1,
                    title: "Đăng nhập",
                    des: "Chọn một trong các mục ở trên website để đăng nhập hoặc - Gõ cụm từ “ Agridential” tại CH Play hoặc App Store để cài đặt ứng dụng"
                },
                {
                    id: 2,
                    title: "Kích hoạt",
                    des: "Vui lòng hoàn thành biểu mẫu bên dưới để xem hướng dẫn chi tiết"
                }
            ]
        };
    }

    render() {
        return (
            <section className=" bg-white mt-3" id='register-section'>
                <div className="container">
                    <Sectitle sClass="sec_title text-center mb_70 text-black mt-5" Title='Hướng dẫn đăng ký tài khoản' TitleP='' />
                    <div className="row flex-row-reverse">
                        <div className="col-lg-12 col-md-10 col-sm-12">
                            <div className="row justify-content-center mb-5 card-process-area">
                                {this.state.integrationItem.map(post => (
                                    <div className="col-lg-4 col-md-4 col-sm-12 px-lg-2 flex-column d-flex p-lg-5 align-content-step" key={post.id}>
                                        <div className='id-card-process'>
                                            <h4>{post.id + 1}</h4>
                                        </div>
                                        <p className='mt-lg-3 mt-2'>{post.title}</p>
                                        <span>{post.des}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}
export default SupportIntegration;
