import React from 'react';

const EventAbout = () => {
    return (
        <section id='feature-section' className="event_about_area position-relative" style={{ backgroundImage: `url("https://res.cloudinary.com/agridential/image/upload/v1620294779/images/gia-ho-tieu-hom-nay-105-giam-do-anh-huong-dich-covid-19-1_sxmgzy.jpg")` }}>
            <img src='https://res.cloudinary.com/agridential/image/upload/v1620296426/images/Group_194_cpxfg3.svg' alt='' className='bg-about-svg' />
            <div className="container">
                <div className="row align-items-center flex-row-mobile">
                    <div className="col-lg-6 col-sm-12">
                        <div className="row">
                            <div className='col-md-5 col-sm-12 padding-mobile-about position-relative col-content-one'>
                                <div className='rounded bg-white d-inline-block p-4  text-center flex-column d-flex position-relative shadow-box margin-mobile'>
                                    <span className='text-dark font-weight-bold'>Sổ nông vụ Blockchain</span>
                                    <span>Giải pháp được xây dựng linh hoạt phù hợp cho việc quản lý các quy trình trồng trọt khác nhau nhằm đáp ứng các tiêu chuẩn đề ra</span>
                                    <div className='icon-column'>
                                        <img src='https://res.cloudinary.com/agridential/image/upload/v1620290690/images/book_2_1_u19dkr.svg' alt='icon-3' className='icon-size-column' />
                                    </div>
                                </div>
                                <div className='rounded bg-white d-inline-block p-4 mt-column text-center flex-column d-flex position-relative shadow-box margin-mobile'>
                                    <span className='text-dark font-weight-bold'>Xác thực nguồn gốc</span>
                                    <span>Kỹ thuật phân tích nhanh và dữ liệu lớn (bigdata) là giải pháp cốt lõi tạo nên độ tin cậy cao phục vụ cho xác thực nguồn gốc và chất lượng của sản phẩm.</span>
                                    <div className='icon-column'>
                                        <img src='https://res.cloudinary.com/agridential/image/upload/v1620290690/images/research_1_k4eyj3.svg' alt='icon-3' className='icon-size-column' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1 col-sm-12' />
                            <div className='col-md-5 col-sm-12 padding-mobile-about position-relative col-content-two'>
                                <div className='rounded bg-white d-inline-block p-4  text-center flex-column d-flex position-relative shadow-box margin-mobile'>
                                    <span className='text-dark font-weight-bold'>Truy xuất nguồn gốc</span>
                                    <span>Được xây dựng trên nền tảng công nghệ Blockchain nhằm đảm bảo tính minh bạch thông tin về nguồn gốc và chất lượng của sản phẩm.</span>
                                    <div className='icon-column'>
                                        <img src='https://res.cloudinary.com/agridential/image/upload/v1620290690/images/Vector_f02ogj.svg' alt='icon-3' className='icon-size-column' />
                                    </div>
                                </div>
                                <div className='rounded bg-white d-inline-block p-4 mt-column text-center flex-column d-flex position-relative shadow-box margin-mobile'>
                                    <span className='text-dark font-weight-bold'>Tư vấn</span>
                                    <span>Đội ngũ chuyên gia tâm huyết và có kinh nghiệm trong lĩnh vực truy xuất nguồn gốc, quản lý chất lượng, chế biến và số hóa nông nghiệp.</span>
                                    <div className='icon-column'>
                                        <img src='https://res.cloudinary.com/agridential/image/upload/v1620290690/images/Group_231_eqrr0h.svg' alt='icon-3' className='icon-size-column' />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="event_about_content text-right">
                            <h2 className="wow fadeInUp">Tính năng nổi bật</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventAbout;