import React from 'react';
const SecurityCustomerLogo = () => {
    return (
        <section className="security_customers_logo_area mt-5" id='partner-section'>
            <div className="container">
                <div className="hosting_title analytices_title text-center">
                    <h2 className="wow fadeInUp">Đối tác của chúng tôi</h2>
                </div>
                <div className="security_inner">
                    <img style={{ maxHeight: '150px' }} className="p_absoulte security_leaf_left wow fadeInDown" data-wow-delay="0.8s" src={'https://res.cloudinary.com/agridential/image/upload/v1620376802/images/l%C3%A1_4_4x_ceuysz.png'} alt="" />
                    <img style={{ maxHeight: '150px' }} className="p_absoulte security_leaf_right wow fadeInDown" data-wow-delay="0.8s" src={'https://res.cloudinary.com/agridential/image/upload/v1620376800/images/l%C3%A1_3_4x_itkdwc.png'} alt="" />
                    <div className="row">
                        <div className="col-lg-2 col-sm-4 col-4">
                            <a href="/#" className="analytices_logo wow fadeInUp"><img src={"https://res.cloudinary.com/agridential/image/upload/v1620284301/images/13173787_921156277997782_8258089408917299303_n_1_wahheb.png"} alt="" /></a>
                        </div>
                        <div className="col-lg-2 col-sm-4 col-4">
                            <a href="/#" className="analytices_logo wow fadeInUp" data-wow-delay="0.2s"><img src={"https://res.cloudinary.com/agridential/image/upload/v1620284300/images/1200px-Logo_%C4%90%E1%BA%A1i_h%E1%BB%8Dc_B%C3%A1ch_Khoa_H%C3%A0_N%E1%BB%99i_1_dy4tzl.png"} alt="" /></a>
                        </div>
                        <div className="col-lg-2 col-sm-4 col-4">
                            <a href="/#" className="analytices_logo wow fadeInUp" data-wow-delay="0.4s"><img src={"https://res.cloudinary.com/agridential/image/upload/v1620284300/images/1200px-Logo-hcmut_1_rufzcd.png"} alt="" /></a>
                        </div>
                        <div className="col-lg-3 col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp" data-wow-delay="0.6s"><img src={"https://res.cloudinary.com/agridential/image/upload/v1621492056/images/image_tdwm33.png"} style={{ maxHeight: '87px' }} alt="" /></a>
                        </div>
                        <div className="col-lg-3 col-sm-4 col-6 d-flex align-items-center">
                            <a href="/#" className="analytices_logo wow fadeInUp" data-wow-delay="0.8s"><img src={"https://res.cloudinary.com/agridential/image/upload/v1620284300/images/Frame_lenih1.png"} alt="" /></a>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp" data-wow-delay="0.2s"><img src={"https://res.cloudinary.com/agridential/image/upload/v1620284300/images/d6f360b81284145496c79f705a23b367_1_ipjyeh.png"} alt="" /></a>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp" data-wow-delay="0.4s"><img src={"https://res.cloudinary.com/agridential/image/upload/v1620284300/images/logtantao_1_znowcp.png"} alt="" /></a>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp" data-wow-delay="0.4s"><img src={"https://res.cloudinary.com/agridential/image/upload/v1621492130/images/image12_iipwiu.png"} style={{ maxHeight: '87px' }} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SecurityCustomerLogo;