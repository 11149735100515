import React from 'react';
import Slider from "./Testimonial"

const EventBanner = () => {
    let data = [
        {
            src: 'https://res.cloudinary.com/agridential/image/upload/v1620808419/images/image-_1_-_1_qrdxsy.jpg'
        },
        {
            src: 'https://res.cloudinary.com/agridential/image/upload/v1620808419/images/image-_2_qpp4pp.jpg'
        },
        {
            src: 'https://res.cloudinary.com/agridential/image/upload/v1620808423/images/image-_2_-_1_rzravu.jpg'
        }
    ]
    return (
        <section className="event_banner_area" id='home-section' style={{ backgroundImage: `url("https://res.cloudinary.com/agridential/image/upload/v1620294126/images/gia-ho-tieu-hom-nay-105-giam-do-anh-huong-dich-covid-19_1_vtdocr.png")` }}>
            <div className='bg-dark-banner' style={{ backgroundImage: `url("https://res.cloudinary.com/agridential/image/upload/v1620294218/images/Rectangle_325_3x_ghzqmr.png")` }}>
                <div className='container '>
                    <div className='row'>
                        <div className='col-md-6 position-relative justify-content-center'>
                            <Slider FooterData={data} />
                        </div>
                        <div className='col-md-6 text-right d-flex flex-column align-items-center justify-content-center'>
                            <span className='text-white font-weight-bold title-top mt-lg-5 text-banner-mobile'>Ứng dụng xác thực và truy xuất nguồn gốc sản phẩm tiêu Việt Nam trên nền tảng Blockchain</span>
                            <span className='text-white mt-3 text-banner-des'>
                                Một dự án của bộ KHCN với mục đích tăng cường khả năng xác thực, truy xuất nguồn gốc và quản lý chất lượng nhằm bảo vệ,nâng cao giá trị và thương hiệu Tiêu tại Việt Nam
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventBanner;