
import React, { Component } from 'react';
import Slider from 'react-slick';

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: false,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        let FooterData = this.props.FooterData;
        var { tClass } = this.props;
        return (
            <section className={`${tClass}`}>
                            <div className="stratup_testimonial_info d-flex align-items-center">
                                <Slider ref={c => (this.slider = c)} className="testimonial_slider_four" {...settings}>
                                    {
                                        FooterData.map(item => {
                                            return (
                                                <div className="item" key={item.id}>
                                                    <div className="author_img">
                                                        <img src={item.src} alt="" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
            </section>
        )
    }
}
export default Testimonial;