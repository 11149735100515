import React from "react";
import EventAbout from "../components/Home/EventAbout";
import EventBanner from "../components/Home/EventBanner";
import CustomNavbar from "../components/Home/CustomNavbar";
import FAQ from "../components/Home/FaqSection";
import EventDetails from "../components/Home/EventDetails";
import Featuresitems from '../components/Home/NewProcess';
import FooterData from "../components/Home/FooterData";
import FooterErp from "../components/Home/FooterErp";
import EventSponser from "../components/Home/SecurityCustomersLogo";
import EventCount from "../components/Home/EventCount";
import Process from "../components/Home/SupportIntegration";
import ContactUs from "../components/Home/ContactUs"

const homesupport = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        mClass="menu_four hosting_menu"
        nClass="w_menu m-auto"
        slogo="sticky_logo"
        hbtnClass="event_btn"
      />
      <EventBanner />
      <EventCount />
      <EventSponser />
      <EventAbout />
      <EventDetails />
      <Process />
      <Featuresitems />
      <FAQ />
      <ContactUs />
      <FooterErp fClass="event_footer_area" FooterData={FooterData} />
    </div>
  );
};
export default homesupport;
